import $ from 'jquery';
import gsap from 'gsap';

$(()=>{
  const $search = $('.js-search-inner');
  const $input = $('.js-search-inner-input').find('input');
  const $candidates = $('.js-search-inner-candidates');
  if($search.length) {
    $input.on('focus', function() {
      $candidates.show();
    });
    $input.on('focusout', function() {
      $candidates.hide();
    });
  }
});