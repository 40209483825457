import $ from 'jquery';
import 'slick-carousel';

$(()=> {
  const target = $('.js-recommend');
  if(target) {
    const image_width = 136;
    let initial = true;
    const slick_option = {
      variableWidth: true,
      // slidesToShow: 9,
      // slidesToScroll: 10,
      infinite: false,
      swipeToSlide: true,
      prevArrow: '<button class="c-button c-button--circle b-recommend__prev"><span class="c-button__body"><i class="c-icon c-icon--paging-prev"></i>前へ</span></button>',
      nextArrow: '<button class="c-button c-button--circle b-recommend__next"><span class="c-button__body"><i class="c-icon c-icon--paging-next"></i>次へ</span></button>',
      responsive: [
        {
          breakpoint: 959.98,
          settings: {
            variableWidth: true,
            arrows: false,
            slidesToShow: 4,
            swipeToSlide: true,
          }
        },
        {
          breakpoint: 729.98,
          settings: {
            variableWidth: true,
            arrows: false,
            slidesToShow: 3,
            swipeToSlide: true,
          }
        },
      ]
    };
    target.slick(slick_option);

    // pcでのprev nextボタンの非表示制御
    function siwtchDisp() {
      if ($(window).width() > 960) {
        let list_width = $('.b-recommend__list').innerWidth();
        target.each(function(k,v){
          let cnt = $(v).find('.slick-slide').length;
          let slick_arrow = $(v).find('.slick-arrow');
          if (cnt * image_width <= list_width) {
            slick_arrow.addClass('slick-disabled');
          } else if (!initial) {
            slick_arrow.removeClass('slick-disabled');
          }
        });
        if (initial) initial = !initial
      }
    }
    $(window).resize(function() {
      siwtchDisp();
    });
    $(window).trigger('resize')
  }

  // footer位置にb-recommend有のとき、mainコンテンツにクラス付与
  const target_footer = $('.js-check-recommend .c-global-footer');
  const target_main = $('.l-main');
  if(target_footer.length && $(window).width() > 960) {
    const target_footer_recomend = $('.l-footer .b-recommend');
    if (target_footer_recomend.length === 0) {
      target_main.removeClass('is-recommend');
    } else {
      target_main.addClass('is-recommend');
    }
  } else {
    target_main.removeClass('is-recommend');
  }
});
