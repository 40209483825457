
class touchCheck {
  constructor() {
    this.moveY = '';
    this.moveX = '';
    this.posiY = 0;
    this.posiX = 0;

  }

  get move() {
    return { y: this.moveY, x: this.moveX };
  }

  // 座標取得処理
  getY(e) {
    // 縦方向の座標を取得
    return (e.targetTouches[0].pageY);
  }
  getX(e) {
    // 横方向の座標を取得
    return (e.targetTouches[0].pageX);
  }
  startCheck(e) {
    // 現在の座標取得
    this.posiY = this.getY(e);
    this.posiX = this.getX(e);
    // 移動距離状態を初期化
    this.moveY = '';
    this.moveX = '';
    // console.log(this.posiY, this.posiX, this.moveY, this.moveX);
  }
  moveCheck(e) {
    // 70px以上移動でスワイプと判断
    const swipeFlag = 70;
    const getX = this.getX(e);
    const getY = this.getY(e);
    if (this.posiX - getX > swipeFlag) {
      // 右→左と判断
      this.moveX = 'left';
    } else if (this.posiX - getX < -swipeFlag) {
      // 左→右と判断
      this.moveX = 'right';
    }
    if (this.posiX - getY > swipeFlag) {
      // 下→上と判断
      this.moveY = 'top';
    } else if (this.posiY - getY < -swipeFlag) {
      // 上→下と判断
      this.moveY = 'bottom';
    }
    // console.log(this.moveY, this.moveX);
  }

  // endCheck() {
  //   let msgX = '';
  //   let msgY = '';
  //   if (this.moveX === 'left') {
  //     msgX = '左へ移動';
  //   } else if (this.moveX === 'right') {
  //     msgX = '右へ移動';
  //   } else {
  //     msgX = '移動なし';
  //   }
  //   if (this.moveY === 'top') {
  //     msgY = '上へ移動';
  //   } else if (this.moveY === 'bottom') {
  //     msgY = '下へ移動';
  //   } else {
  //     msgY = '移動なし';
  //   }
  //   console.log('（横移動）' + msgX + ':' + '（縦移動）' + msgY);
  //   // $('#msg').text('（横移動）'+msgX+':'+'（縦移動）'+msgY);
  // }
}

module.exports = touchCheck;
// export { moveCheck };

// const touchCheck = {};
// touchCheck.startCheck = (event) => {
//   let moveY,modeX, posiY, posiX;
//   /** 現在の座標取得 */
//   posiY = getY(event);
//   posiX = getX(event);

//   /** 移動距離状態を初期化 */
//   moveY = '';
//   moveX = '';

//   /** 表示メッセージを初期化 */
//   msgY = '';
//   msgX = '';
// }
// export { startCheck };

// const moveCheck = (fixed) => {
//   /** ①初期メッセージ出力 */
//   $('#msg').text('スマホで下の枠を指でスワイプしてください');
 
//   /** ②指が触れたか検知 */
//   $('#box').on('touchstart', start_check);
 
//   /** ③指が動いたか検知 */
//   $('#box').on('touchmove', move_check);
 
//   /** ④指が離れたか検知 */
//   $('#box').on('touchend', end_check);
 
//   /** 変数宣言 */
//   var moveY,modeX, posiY, posiX;
 
 
//   // ⑤タッチ開始時の処理

 
//   // ⑥スワイプ中の処理
 
//   // ⑦指が離れた時の処理

 
 
//   // 座標取得処理
//   function getY(event) 
//   {
//     //縦方向の座標を取得
//     return (event.originalEvent.touches[0].pageY);
//   }
 
//   function getX(event) 
//   {
//     //横方向の座標を取得
//     return (event.originalEvent.touches[0].pageX);
//   }
// }

// export { moveCheck };