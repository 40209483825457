import $ from 'jquery';
import gsap from 'gsap';
import { backfaceFixed } from './backfaceFixed.js';

$(()=>{
  const $filter = $('.js-filter');
  const $show = $('.js-filter-show');
  const $close = $('.js-filter-close');
  if($filter.length) {
    $show.on('click', function() {
      gsap.to($filter, 0.5, {
        display: 'block',
        opacity: 1,
        ease: 'circ',
      });
      $('html').addClass('is-filter-show');
      backfaceFixed(true);
    });
    $close.on('click', function() {
      gsap.to($filter, 0.5, {
        display: 'none',
        opacity: 0,
        ease: 'circ'
      });
      $('html').removeClass('is-filter-show');
      backfaceFixed(false);
    });
  }
});