import $ from 'jquery';
import flatpickr from 'flatpickr';
import { ja } from "flatpickr/dist/l10n/ja.js"
import rangePlugin from "flatpickr/dist/plugins/rangePlugin.js"
import rangeMonthPlugin from "./rangeMonthPlugin.js"
import monthSelectPlugin from './monthSelectPlugin.js';

const target = document.querySelectorAll('.js-range-calendar');
const target_month = document.querySelectorAll('.js-range-calendar-month');

$(()=> {
  if(target.length) {
    for(let i = 0; i < target.length; i++) {
      const input = target[i].querySelectorAll('.js-range-calendar__input');
      const end = input[1];
      const optional_config = {
        locale: 'ja',
        plugins: [new rangePlugin({ input: end })],
        dateFormat: 'Y.m.d',
        disable: [// デベロッパーツール使用時にflatpickrが使えなくなるバグ対策
          {},
        ]
      }
      flatpickr(input, optional_config);
    }
  }

  if(target_month.length) {
    for(let i = 0; i < target_month.length; i++) {
      const input_month = target_month[i].querySelectorAll('.js-range-calendar-month__input');
      const end_month = input_month[1];
      const optional_config_month = {
        locale: 'ja',
        mode: 'range',
        plugins: [
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: "Y.m",
            altFormat: "Y.m",
            input2: end_month
          }),
          new rangeMonthPlugin({ input: end_month })
        ],
        disable: [// デベロッパーツール使用時にflatpickrが使えなくなるバグ対策
          {},
        ]
      }
      flatpickr(input_month, optional_config_month);
    }
  }
});
