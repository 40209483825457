import $ from 'jquery';

const target = $('.js-product-infomation');

$(()=> {
  if(target.length) {
    // const max_width = 1280;
    const max_width = 1920;
    const target_height = target.height();
    const header_height = $('.c-global-header').height();
    const set_target_position = ()=> {
      const scroll = $(window).scrollTop();
      const body_width = $('body').width();
      const content_height = $('.l-main').height();
      if (!$('body').hasClass('is-fixed')) {
        if(scroll >= header_height) {
          const fix_right = body_width > max_width ? (body_width - max_width) / 2 : 0;
          target.addClass('is-fixed');
          target.css('right', `${ fix_right }px`);
        } else {
          target.removeClass('is-fixed');
          target.css('right', '0');
        }
        if(scroll >= content_height - target_height) {
          target.addClass('is-fixed-end');
          target.css('right', '0');
        } else {
          target.removeClass('is-fixed-end');
        }
      }
    }
    $(window).on('scroll', function() {
      set_target_position();
    });
    // コンテンツの高さを監視
    const content = document.querySelector('.l-main');
    const observer = new ResizeObserver((entries) => {
      set_target_position();
    });
    observer.observe(content);
  }
});