import $ from 'jquery';

$(()=> {
  // AjaxZip3
  const target_ajaxzip = $('.c-button--ajaxzip3 .c-button__body');
  if(target_ajaxzip) {
    $(target_ajaxzip).on('click', function(){
      AjaxZip3.zip2addr('zip',null,'pref','addr01');
      //成功時に実行する処理
      AjaxZip3.onSuccess = function() {
        setTimeout(() => {
          $('[name="addr02"]')[0].focus();
        }, 10);
      };
      //失敗時に実行する処理
      AjaxZip3.onFailure = function() {
        alert('郵便番号に該当する住所が見つかりません');
      };
      return false;
    });
  }
})
