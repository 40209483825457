import $ from 'jquery';
import gsap from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

$(()=> {
  const $moreTop = $('.js-more-top');
  if($moreTop.length) {
    const $openTop = $moreTop.find('.js-more-top__open');
    const $closeTop = $moreTop.find('.js-more-top__close');
    const $bodyTop = $moreTop.find('.js-more-top__body');
    $openTop.on('click', function() {
      // $(this).hide();
      $(this).parent().find('.js-more-top__close').show();
      gsap.to($(this).parent().parent().find('.js-more-top__body'), 1, {
        height: 'auto',
        ease: 'circ'
      });
    });
    $closeTop.on('click', function() {
      $(this).parent().find('.js-more-top__open').show();
      $(this).hide();
      gsap.to($(this).parent().parent().find('.js-more-top__body'), 1, {
        height: '0',
        ease: 'circ'
      });
    });
  }
});