import $ from 'jquery';
import gsap from 'gsap';

$(()=>{
  const toggle = $('.js-toggle');
  const body = $('.js-toggle__body');
  if(toggle) {
    const length = toggle.length;
    toggle.click(function(){
      const index = toggle.index(this);
      const open = $(this).attr('class').match(/is-open/);
      if(open) {
        $(this).removeClass('is-open');
        gsap.to(body.eq(index), 0.5, {
          height: '0',
          duration: 1,
          ease: 'circ',
        });
      } else {
        $(this).addClass('is-open');
        gsap.to(body.eq(index), 0.5, {
          height: 'auto',
          duration: 1,
          ease: 'circ',
        });
      }
    })

    // 初期オープン状態
    toggle.each(function(k,v){
      if ($(v).hasClass('is-open')) {
        $(this).next('.js-toggle__body').css('height', 'auto')
      }
    })
  }
});


/*
toggle() {
  if(this.more) {
    gsap.to('.about__body', {
      height: 180,
      duration: 1,
      ease: 'circ',
    });
    this.more = false;
  } else {
    gsap.to('.about__body', {
      height: 'auto',
      duration: 1,
      ease: 'circ',
    });
    this.MeasureUtilAccordionOpen('ソリューションフォーラムについて');
    this.more = true;
  }
},
*/