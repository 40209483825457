import $ from 'jquery';
import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
import gsap from 'gsap';
// import { ScrollToPlugin } from "gsap/ScrollToPlugin";
// gsap.registerPlugin(ScrollToPlugin);

$(() => {
  const $target = $('.js-grid');
  if($target) {
    jQueryBridget('masonry', Masonry, $);
    imagesLoaded.makeJQueryPlugin( $ );
    let loaded = 0;
    const $item = $('.js-grid-item');
    const $more = $('.js-grid-more');
    const $close = $('.js-grid-close');
    const itemLength = $item.length;
    let status = 0;

    $.fn.isVisible = function() {
      return $.expr.filters.visible(this[0]);
    };
    let $grid = $target.masonry({
      itemSelector: '.js-grid-item',
      percentPosition: true,
      gutter: 8,
      transitionDuration: 0
        // columnWidth: '.grid-sizer'
    });
    $grid.masonry('on', 'layoutComplete', function() {
      // console.log('layout is complete', status);
      if(status === 1) {
        $item.not('.is-more').addClass('is-visible');
      } else if(status === 2) {
        $item.addClass('is-visible');
      }
    });
    $grid.imagesLoaded().progress(function() {
      loaded++;
      // console.log(loaded, itemLength)
      if(loaded === itemLength) {
        status = 1;
        $grid.masonry();
      }
    });
    if($more) {
      $more.on('click', function() {
        $target.addClass('is-show-more');
        $more.hide();
        setTimeout(()=> {
          status = 2;
          $grid.masonry();
        }, 100);
      })
      // $close.click(function() {
      //   $close.hide();
      //   $more.show();
      //   gsap.to($more_item, 0, {
      //     display: 'none',
      //     duration: 0,
      //     ease: 'circ',
      //     onComplete: function() {
      //       $grid.masonry('layout');
      //       gsap.to(window, {scrollTo: {y: '.js-grid', offsetY:0}});
      //       // gsap.to(window, {scrollTo: {y: '.js-grid-more', offsetY:0}});
      //     }
      //   });
      // });
    }
  }
})