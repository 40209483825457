import $ from 'jquery';
import gsap from 'gsap';

$(()=> {
  const $target = $('.js-checkbox-family');
  if($target.length) {
    const $unit = $('.js-checkbox-family__unit');
    const $parent = $unit.find('.js-checkbox-family__parent input');
    const $child = $unit.find('.js-checkbox-family__child input');
    const $toggle = $target.find('.js-checkbox-family__toggle');
    const toggleStatus = [];
    $toggle.each(function(i) {
      // console.log('$toggle', i);
      toggleStatus[i] = false;
    });
    // console.log('toggleStatus', $unit.length)
    $parent.on('change', function() {
      const index = $parent.index(this);
      const checked = $(this).prop('checked');
      const $child = $unit.eq(index).find('.js-checkbox-family__child input');
      // console.log(index, checked)
      if(checked) {
        $child.prop('checked', true);
      } else {
        $child.prop('checked', false);
      }
    });
    const checkAll = ($childs)=> {
      let all = true;
      $childs.each(function(index, elm) {
        const checked = $(elm).prop('checked');
        if(!checked) {
          all = false;
        }
      })
      return all;
    };
    $child.on('change', function() {
      const checked = $(this).prop('checked');
      const $parent = $(this).parents('.js-checkbox-family__unit').find('.js-checkbox-family__parent input');
      const $childs = $(this).parents('.js-checkbox-family__unit').find('.js-checkbox-family__child input');
      if(!checked) {
        $parent.prop('checked', false);
      } else {
        if(checkAll($childs)) {
          $parent.prop('checked', true);
        }
      }
    });
    $toggle.on('click', function() {
      const index = $toggle.index(this);
      // const $child = $(this).parents('.js-checkbox-family-unit').find('.b-checkbox-family__child');
      const $child = $unit.eq(index).find('.b-checkbox-family__child');
      
      if(toggleStatus[index]) {
        $(this).removeClass('is-open');
        gsap.to($child, 0.3, {
          height: '0',
          duration: 1,
          ease: 'circ',
        });
        toggleStatus[index] = false;
      } else {
        $(this).addClass('is-open');
        gsap.to($child, 0.3, {
          height: 'auto',
          duration: 1,
          ease: 'circ',
        });
        toggleStatus[index] = true;
      }
    })
  }
})


