import $ from 'jquery';

$(()=> {
  const target = document.querySelector('.js-float-filter-area');
  const $target = $(target);
  if($target.length) {
    let header_height = $('.c-global-header').outerHeight();
    let footer_height = $('.c-global-footer').outerHeight();
    let target_height = $target.outerHeight();
    let window_width = $(window).width();
    let window_height = $(window).height();
    let content_height = $('.l-content').outerHeight();
    let body_height = $('body').height();
    let scroll = 0;
    let scroll_direction = 'down';
    let fixed = false;

    let scroll_position = 0;
    let absolute_position = 0;
    let up = false;
    let bottom = false;
    let absolute = false;

    const resetFloat = ()=> {
      $target.removeClass('is-fixed');
      $target.removeClass('is-absolute');
      $target.attr('style', '');
    }
    const setFloat = ()=> {
      scroll_position = $(window).scrollTop();
      const content_height = $('.l-main').height();
      const fixed_position = target_height + header_height - window_height + absolute_position;
      if (fixed_position >= 0) {
        if(scroll_direction === 'down') {
          // const fixed_position = target_height + header_height - window_height + absolute_position;
          const absolute_position_bottom = body_height - window_height - header_height - 192;
          if(up && !absolute) {
            resetFloat();
            absolute_position = scroll_position - header_height;
            $target.addClass('is-absolute');
            $target.css({top: `${ absolute_position }px` });
            fixed = false;
            absolute = true;
          }
          if(scroll_position < header_height) {
            resetFloat();
            bottom = false;
            absolute_position = 0;
            absolute = false;
          } else if(scroll_position >= fixed_position && scroll_position < absolute_position_bottom) {
            if(!fixed) {
              resetFloat();
              $target.addClass('is-fixed');
              $target.css({bottom: '0px' });
              fixed = true;
              bottom = false;
              absolute = false;
            }
          } else if(scroll_position >= absolute_position_bottom) {
            resetFloat();
            fixed = false;
            $target.addClass('is-absolute');
            $target.css({bottom: '0px' });
            bottom = true;
            absolute = false;
          }
          up = false;
        } else if(scroll_direction === 'up') {
          if(!up && !absolute) {
            resetFloat();
            if(bottom) {
              // absolute_position = body_height - target_height - (window_height - footer_height) + 170;
              absolute_position = body_height - target_height - footer_height - 114;
            } else {
              absolute_position = scroll_position - header_height - (target_height - window_height);
            }
            $target.addClass('is-absolute');
            $target.css({top: `${ absolute_position }px` });
            fixed = false;
            absolute = true;
          }
          if(scroll_position < header_height) {
            resetFloat();
            fixed = false;
            absolute = false;
          } else if(scroll_position - header_height < absolute_position) {
            if(!fixed) {
              resetFloat();
              $target.addClass('is-fixed');
              $target.css({top: '0px' });
              fixed = true;
              absolute = false;
            }
          }
          up = true;
        }
      } else if (!$('body').hasClass('is-fixed')) {
        if(scroll >= content_height - target_height + header_height) {
          resetFloat();
          $target.addClass('is-absolute');
          $target.css({bottom: `0px` });
        } else {
          if ($target.hasClass('is-absolute')) {
            resetFloat();
          }
          if(scroll >= header_height) {
            $target.addClass('is-fixed');
            $target.css({top: `0px` });
          } else {
            resetFloat();
          }
        }
      }
    }
    const check_window_size = ()=> {
      return (
        content_height > target_height
        && window_width > 960
      ) ? true : false;
    }
    $(window).on('scroll', function(e) {
      const current_scroll = $(this).scrollTop();
      scroll_direction = current_scroll < scroll ? 'up' : 'down';
      scroll = current_scroll;
      if(check_window_size()) {
        setFloat();
      }
    });
    $(window).on('resize', function() {
      header_height = $('.c-global-header').outerHeight();
      target_height = $target.outerHeight();
      window_width = $(window).width();
      window_height = $(window).height();
      content_height = $('.l-content').outerHeight();
      body_height = $('body').height();
      if(check_window_size()) {
        setFloat();
      } else {
        resetFloat();
      }
    });
    // $(window).on('mousewheel', function(e) {
    //   const deltaY = e.originalEvent.wheelDeltaY;
    //   wheelDelta = deltaY ? 'up' : 'down';
    // });

    if(check_window_size()) {
      setFloat();
    }
    const observer = new ResizeObserver(() => {
      target_height = $target.outerHeight();
    });
    observer.observe(target);
    const observer_content = new ResizeObserver(() => {
      // console.log('observer_content');
      content_height = $('.l-content').outerHeight();
      body_height = $('body').height();
      scroll_direction = 'down';
      setFloat();
    });
    observer_content.observe(document.querySelector('.l-content'));
  }
});