import $ from 'jquery';
import gsap from 'gsap';

$(()=>{
  const $search = $('.js-search');
  const $input = $('.js-search-input').find('input');
  const $show = $('.js-search-show');
  const $close = $('.js-search-close');
  const $history = $('.js-search-history');
  const $candidates = $('.js-search-candidates');
  if($search.length) {

    const toggleCandidates = (input_text) => {
      if (input_text.length > 0) {
        $candidates.show();
        $history.hide();
      } else {
        $history.show();
        $candidates.hide();
      }
    }
    $show.on('click', function() {
      gsap.to($search, 0.5, {
        display: 'block',
        opacity: 1,
        ease: 'circ',
      });
    });
    $close.on('click', function() {
      gsap.to($search, 0.5, {
        display: 'none',
        opacity: 0,
        ease: 'circ',
      });
    });
    
    $input.on('focus focusout keyup', function() {
      toggleCandidates($(this).val());
    });

    // xボタンクリックによるクリア対応
    $input.on('click', function() {
      const _this = this
      setTimeout(()=>{
        toggleCandidates($(_this).val())
      }, 10) 
    })
  }
});