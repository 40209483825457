import $ from 'jquery';
import gsap from 'gsap';
import { backfaceFixed } from './backfaceFixed.js';

const focusableElementsString = 'a[href], area[href], input:not([disabled]), button:not([disabled]), object, embed, [tabindex="0"], [contenteditable]';
let focusOnDialog = false;
$(()=>{
  const $modal = $('.js-modal');
  const $modal_initial = $('.js-modal--initial');
  const $show = $('.js-modal-show');
  const $close = $('.js-modal-close');
  const $body = $("body");

  function disableScroll(event) {
    event.preventDefault();
  }

  $.fn.modal = function(state){
    const $this = this;
    if (state === 'show' || (state === 'toggle' && !$body.hasClass('is-fixed'))) {
      gsap.to($this, 0.5, {
        display: 'flex',
        opacity: 1,
        ease: 'circ',
      });
      // $('html, body').addClass('is-modal-show');
      backfaceFixed(true);
      $body.addClass('is-fixed');
      $this.addClass('is-show');
    } else if (state === 'hide' || (state === 'toggle' && $body.hasClass('is-fixed'))) {
      const filter = $('html').hasClass('is-filter-show');
      gsap.to($this, 0.5, {
        display: 'none',
        opacity: 0,
        ease: 'circ',
      });
      // $('html, body').removeClass('is-modal-show');
      $body.removeClass('is-fixed');
      $this.removeClass('is-show');
      focusOnDialog = false;
      if(!filter) {
        backfaceFixed(false);
      }
    }
  };

  if($modal.length) {
    $show.on('click', function() {
      const dataVal = $(this).data('modal');
      let $target = null;
      $modal.each((i, elm)=> {
        const targetDataVal = $(elm).data('modal-target');
        if(targetDataVal === dataVal) {
          $target = $(elm);
        }
      });
      $target.modal('show');
    });
    $close.on('click', function() {
      const $parent = $(this).parents('.js-modal');
      $parent.modal('hide');
    });
  }
  if($modal_initial.length) {
    $modal_initial.modal('show');
  }

  // 表示中のモーダル上の要素がフォーカスされている判定
  let modals = Array.from(document.getElementsByClassName('js-modal'));
  modals.forEach(function(modal) {
    modal.addEventListener("focusin", function(){
      if (modal.classList.contains('is-show')) {
        focusOnDialog = true;
      }
    })
  });
  if ($modal.length) {
    // 入力キー動作(Tab切り替え,Escボタンclose)
    $(window).keydown(function(e){
      $modal.each((i, elm)=> {
        if ($(elm).hasClass('is-show')) {
          const focusableElements = [].slice.call(elm.querySelectorAll(focusableElementsString));
          const firstTabStop = focusableElements[0]
          const lastTabStop = focusableElements[focusableElements.length -1]
          if (!focusOnDialog) {
            document.activeElement.blur();
            elm.focus();
          }
          if (e.keyCode === 9) {
            if(e.shiftKey) {
              if(document.activeElement === firstTabStop) {
                e.preventDefault()
                $(lastTabStop).focus()
              }
            } else {
              if (document.activeElement === lastTabStop) {
                e.preventDefault();
                $(firstTabStop).focus();
              }
            }
          }
          if (e.keyCode === 27) {
            $(elm).modal('hide')
          }
        }
      });
    });
    const setFillHeightModal = () => {
      const vh = window.innerHeight * 0.01;
      modals.forEach((modal) => {
        modal.style.height = window.innerHeight + 'px';
      })
    }
    // リサイズで高さ再計算
    window.addEventListener('resize', setFillHeightModal);
    // initial
    setFillHeightModal();
  }
});

window.addEventListener('load', function(){
  let body = document.getElementsByTagName('body')[0];
  let html = document.getElementsByTagName('html')[0];
  
  class Modal {
    constructor(element) {
      this.element = element
    }
    show() {
      gsap.to(this.element, 0.5, {
        display: 'flex',
        opacity: 1,
        ease: 'circ',
      });
      backfaceFixed(true);
      body.classList.add("is-fixed");
      this.element.classList.add("is-show");
    }
    hide() {
      const filter = html.classList.contains("is-filter-show");
      gsap.to(this.element, 0.5, {
        display: 'none',
        opacity: 0,
        ease: 'circ',
      });
      body.classList.remove('is-fixed')
      this.element.classList.remove("is-show")
      if(!filter) {
        backfaceFixed(false);
      }
      document.activeElement.blur();
      focusOnDialog = false
    }
  }
  window.Modal = Modal
});