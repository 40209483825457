import $ from 'jquery';

$(()=> {
  const $tab = $('.js-tab');
  if($tab.length) {
    const tab = [];
    $tab.each(function(i, elm) {
      tab[i] = {};
      tab[i].target = $tab.eq(i);
      tab[i].button = tab[i].target.find('.js-tab__button');
      tab[i].buttonCurrent = tab[i].target.find('.js-tab__button.is-current');
      tab[i].unit = tab[i].target.find('.js-tab__unit');
      tab[i].current = tab[i].buttonCurrent.length ? tab[i].button.index(tab[i].buttonCurrent) : 0;

      tab[i].button.eq(tab[i].current).addClass('is-current');
      tab[i].unit.eq(tab[i].current).addClass('is-current');

      tab[i].button.on('click', function() {
        const index = tab[i].button.index(this);
        tab[i].current = index;
        tab[i].button.removeClass('is-current');
        tab[i].unit.removeClass('is-current');
        tab[i].button.eq(tab[i].current).addClass('is-current');
        tab[i].unit.eq(tab[i].current).addClass('is-current');
      });
    });
  }
});