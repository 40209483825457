import $ from 'jquery';
import gsap from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

$(()=> {
  const $more = $('.js-more');
  if($more.length) {
    const $open = $more.find('.js-more__open');
    const $close = $more.find('.js-more__close');
    const $body = $more.find('.js-more__body');
    $open.on('click', function() {
      // $open.hide();
      $close.show();
      gsap.to($body, 1, {
        height: 'auto',
        ease: 'circ'
      });
    });
    $close.on('click', function() {
      $open.show();
      $close.hide();
      gsap.to($body, 1, {
        height: '0',
        ease: 'circ'
      });
    });
  }
});