import $ from 'jquery';
import gsap from 'gsap';
import Zoomist from 'zoomist';
import { backfaceFixed } from './backfaceFixed.js';
import touchCheck from './touchCheck';

$.event.special.touchstart={setup:function(e,t,s){t.includes("noPreventDefault")?this.addEventListener("touchstart",s,{passive:!1}):this.addEventListener("touchstart",s,{passive:!0})}},$.event.special.touchmove={setup:function(e,t,s){t.includes("noPreventDefault")?this.addEventListener("touchmove",s,{passive:!1}):this.addEventListener("touchmove",s,{passive:!0})}},$.event.special.wheel={setup:function(e,t,s){this.addEventListener("wheel",s,{passive:!0})}},$.event.special.mousewheel={setup:function(e,t,s){this.addEventListener("mousewheel",s,{passive:!0})}};

const $target = document.querySelector('.js-panzoom');

if($target) {
  // const $body = $target.querySelector('.js-panzoom-body');
  const $body = $($target).find('.js-panzoom-body');
  class panzoom extends touchCheck {
    constructor(target) {
      super(target);
      this.target = target;
      this.items = target.find('.js-panzoom-item');
      this.length = this.items.length;
      this.current = 0;
      this.zoom = {
        state: false,
        zoomist: $target.querySelector('.js-zoomist'),
        target: null,
        obj: null,
        timeStamp: 0,
        move: false,
        mousedown: false
      };
      this.button = {
        show: $('.js-panzoom-show'),
        close: $('.js-panzoom-close'),
        prev: target.find('.js-panzoom-prev'),
        next: target.find('.js-panzoom-next'),
        dot: null
      }
      this.zoom.target = $(this.zoom.zoomist);
      this.init();
    }
    init() {
      this.setEventListener();
      this.setDots();
      // this.show(0); // 開発用
      // this.setPanZoom();
    }
    setEventListener() {
      const self = this;

      /** モーダル表示非表示 */
      self.button.show.on('click', function() {
        const index = self.button.show.index(this);
        self.show(index);
      });
      self.button.close.on('click', function() {
        self.close();
      });
      /** / モーダル表示非表示 */

      /** ページ送りの設定 */
      self.target.on('touchstart', function(e) {
        self.startCheck(e);
        
      });
      self.target.on('touchmove', function(e) {
        self.moveCheck(e);
      });
      self.target.on('touchend', function() {
        if(!self.zoom.state) {
          const moveX = self.move.x;
          const moveY = self.move.y;
          if(moveX === 'left') {
            self.next();
          } else if(moveX === 'right') {
            self.prev();
          }
          if(!moveX && moveY === 'bottom') {
            self.close();
          }
        }
      });
      self.button.next.on('click', function() {
        self.next();
      });
      self.button.prev.on('click', function() {
        self.prev();
      });
      /** / ページ送りの設定 */

      /** ズームモード表示の設定 */
      const $img = this.items.find('img');
      $img.on('click', function() {
        self.zoomMode();
      });
      /** / ズームモード表示の設定 */
    }
    zoomMode() {
      this.zoom.state = !this.zoom.state;
      this.zoom.target.removeClass('is-moved');
      // 
      if(this.zoom.state) {
        const self = this;
        this.items[this.current].classList.add('is-zoom');
        self.zoom.target.on('mousedown touchstart', function(e) {
          // console.log('mousedown');
          self.zoom.timeStamp = e.timeStamp;
          self.zoom.mousedown = true;
          // console.log($(this));
        });
        self.zoom.target.on('mousemove touchmove', function(e) {
          if(self.zoom.mousedown) {
            self.zoom.target.addClass('is-moved');
            self.zoom.move = true;
            // console.log(self.zoom.move);
          }
        });
        self.zoom.target.on('mouseup touchend', function(e) {
          console.log('mouseup', self.zoom.move);
          // console.log(e)
          const type = e.type;
          self.zoom.target.removeClass('is-moved');
          if(!self.zoom.move && type !== 'touchend') {
            self.zoomMode();
          }
          self.zoom.mousedown = false;
            self.zoom.move = false;
          // console.log(e.timeStamp, self.zoom.timeStamp)
          // if((e.timeStamp - self.zoom.timeStamp) < 500 ) {
          //   self.zoomMode();
          // }
        });
        self.setZoomist();
      } else {
        this.zoomReset();
      }
    }
    setZoomist() {
      const self = this;
      const imgsrc = $(this.items).eq(this.current).attr('data-zoom-src');
      self.zoom.target.attr('data-zoomist-src', imgsrc);
      self.zoom.target.parent().addClass('is-active');
      const $wrapper = $($target.querySelector('.c-panzoom__wrapper'));
      self.zoom.obj = new Zoomist(self.zoom.zoomist, {
        wheelable: false,
        pinchable: false,
        height:`${($($wrapper).outerHeight() - 28) / ($($wrapper).outerWidth() - 28) * 100}%`,
        on: {
          ready() {
            self.zoom.obj.zoomTo(2);
          },
          resize() {
            if(!self.zoom.obj) {
              const modules = Boolean(self.zoom.obj['__modules__']);
              if(modules) self.zoom.obj.destroy();
              if(self.zoom.state && modules) self.setZoomist();
            }
          }
        }
      });
    }
    zoomReset() {
      this.zoom.state = false;
      this.zoom.target.off();
      if(this.zoom.obj) this.zoom.obj.destroy();
      this.zoom.obj = null;
      this.items.eq(this.current).removeClass('is-zoom');
      this.zoom.target.parent().removeClass('is-active');

      this.zoom.mousedown = false;
      this.zoom.move = false;
    }
    show(i) {
      this.current = i;
      this.select();
      gsap.to($target, 0.3, {
        display: 'flex',
        opacity: 1,
        ease: 'circ',
      });
      backfaceFixed(true);
    }
    close() {
      gsap.to($target, 0.3, {
        display: 'none',
        opacity: 0,
        ease: 'circ',
      });
      this.zoomReset();
      backfaceFixed(false);
    }
    prev() {
      if(this.current > 0) {
        this.current = this.current - 1;
      } else {
        this.current = this.length - 1;
      }
      this.select();
    }
    next() {
      if(this.current < this.length - 1) {
        this.current = this.current + 1;
      } else {
        this.current = 0;
      }
      this.select();
    }
    select() {
      this.items.removeClass('is-selected');
      this.items.eq(this.current).addClass('is-selected');
      this.moveDot();
    }
    moveDot() {
      this.dot.removeClass('is-current');
      this.dot.eq(this.current).addClass('is-current');
    }
    setDots() {
      const dotsItem = ()=> {
        let item = '';
        for(let i = 0; i < this.length; i++) {
          const currentClass = this.current === i ? ' is-current' : '';
          item = `${item}<li class="c-panzoom__dots__item js-panzoom-dot${currentClass}">
            <span class="c-panzoom__dots__item__inner">${ i + 1 }</span>
          </li>`;
        }
        return item;
      }
      const dots = `<div class="c-panzoom__dots">
          <ul class="c-panzoom__dots__list">
            ${ dotsItem() }
          </ul>
        </div>
      </div>`;
      
      const self = this;
      self.target.append(dots);
      self.dot = $('.js-panzoom-dot');
      self.dot.on('click', function() {
        self.current = self.dot.index(this);
        self.select();
      });
    }
  }
  const obj = new panzoom($body);
}
