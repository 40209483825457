window.addEventListener('load', function(){
  class Toast {
    constructor(element) {
      this.element = element
    }
    show(time=0) {
      this.element.classList.add("is-show")
      if (Number.isInteger(time) && time > 0) {
        let _this = this
        setTimeout(function(){
          _this.element.classList.remove("is-show")
        }, time)
      }
    }
    hide() {
      this.element.classList.remove("is-show")
    }
  }
  window.Toast = Toast

  function showToast(target) {
    target.classList.add("is-show")
  }

  function hideToast() {
    if (document.getElementsByClassName('js-alert').length) {
      let messages = Array.from(document.getElementsByClassName('js-alert'));
      messages.forEach(function(message) {
        message.classList.remove("is-show");
      });
    }
  }

  // if (document.getElementsByClassName('js-alert').length) {
  //   document.addEventListener('click', (e) => {
  //     let showElements = document.querySelectorAll('.js-alert.is-show');
  //     if (showElements.length) {
  //       let currentElement = e.target.closest('.js-alert.is-show');
  //       if (!showElements[0].isEqualNode(currentElement)) {
  //         hideToast();
  //       }
  //     }
  //   }, true)
  // }
  if (document.getElementsByClassName('js-close-toast').length) {
    let closeToasts = Array.from(document.getElementsByClassName('js-close-toast'));
    closeToasts.forEach(function(closeToast) {
      closeToast.addEventListener('click', function() {
        hideToast();
      });
    });
  }
  // js-alert-initで初期表示
  if (document.getElementsByClassName('js-alert-init').length) {
    let toasts = Array.from(document.getElementsByClassName('js-alert-init'));
    toasts.forEach(function(toast) {
      showToast(toast);
    });
  }
});