/*----------------------------------------------------
  Mega Menu
----------------------------------------------------*/
import $ from 'jquery';
import gsap from 'gsap';

$(()=> {
  let 
  $body = $("body"),
  $megamenu = $('.js-megamenu'),
  $megamenu_navi = $megamenu.find('.js-megamenu-navi'),
  $megamenu_main = $('.js-megamenu-main'),
  $megamenu_bg = $('.js-megamenu__bg'),
  $content = $('.js-megamenu-content'),
  selected_content_id = '',
  $close_btn = $('.c-megamenu__close'),
  sethover,
  $header = $('.c-global-header'),
  initial = true;

  function megamenu_initialize () {
    $megamenu_main.css('height', '0px');
    bg_position_adjust();
  }

  function megamenu_close () {
    $megamenu_navi.removeClass('is-active');
    // $megamenu_main.css('height', '0px')
    gsap.to($megamenu_main, {
        height: 0,
        duration: 0.3,
        ease: 'circ',
    });
    
    $content.hide();
    selected_content_id = ''
    $megamenu_bg.removeClass('is-active')
    $megamenu_bg.removeClass('is-fixed')
  }

  function bg_position_adjust () {
    const body_height = document.querySelector('body').clientHeight;
    const clientHeight = document.documentElement.clientHeight
    // 画面高さ小さい時のはみ出対策
    if ( window.innerWidth >= 960 && body_height - clientHeight <= 72 ) {
      $megamenu_bg.css('height', 'calc(100vh - 82px)');
    }
  }

  if($megamenu.length) {
    megamenu_initialize ();

    // 背景固定
    $(window).scroll(function(){
      if ($megamenu_bg.hasClass('is-active')) {
        if (window.pageYOffset >= $header.innerHeight()) {
          $megamenu_bg.addClass('is-fixed')
        } else {
          $megamenu_bg.removeClass('is-fixed')
        }
      }
    });

    // リサイズ
    $(window).resize(function(){
      bg_position_adjust();
      if ($megamenu_bg.hasClass('is-active')) {
        let $selected_content = $('#' + selected_content_id);
        let h = $selected_content.innerHeight();
        // $megamenu_main.css('height', h + 'px');
        gsap.to($megamenu_main, {
          height: h,
          duration: 0.1,
          ease: 'circ',
        });
      }
    });

    $megamenu_navi.on({
      'mouseover': function(){
        let tmp_selected_content_id = $(this).data('id')
        // console.log('nav mouseover')
        // console.log(tmp_selected_content_id)
        if (selected_content_id != tmp_selected_content_id) {
          const _this = this;

          sethover = setTimeout(function(){
            selected_content_id = tmp_selected_content_id;
            $megamenu_navi.removeClass('is-active');
            $content.hide();
            let $selected_content = $('#' + selected_content_id);
            $(_this).addClass('is-active')
            $megamenu_bg.addClass('is-active')

            $selected_content.fadeIn(500);
            let h = initial? 'auto': $selected_content.innerHeight();
            if (initial) initial = !initial
            // $megamenu_main.css('height', h + 'px');
            gsap.to($megamenu_main, {
              height: h,//'auto',
              duration: 0.3,
              ease: 'circ',
            });
          }, 100);
        }
      },
      'mouseleave': function() {
        clearTimeout(sethover);

        let flg = false
        $(':hover').each(function () {
          if ($(this).data('id') && selected_content_id !== $(this).data('id')) {
            flg = true
          }
          if (flg) {
            megamenu_close();
          }
      });
      }
    });

    $megamenu.on({
      'mouseleave': function() {
        megamenu_close();
      }
    });

    $megamenu_bg.on({
      'mouseover': function(e){
        if (window.innerWidth >= 960 ) {
          megamenu_close();
        }
      },
      'click': function(e){
        megamenu_close();
      }
    });
    $close_btn.on('click', function(){
      megamenu_close();
    })
  }
})