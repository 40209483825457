import $ from 'jquery';
import flatpickr from 'flatpickr';
import { ja } from "flatpickr/dist/l10n/ja.js"


const target = $('.js-date-picker');

$(()=> {
  if(target.length) {
    const optional_config = {
      locale: 'ja',
      dateFormat: 'Y.m.d'
    }
    flatpickr(target, optional_config);
  }
});