import $ from 'jquery';
import gsap from 'gsap';
import { backfaceFixed } from './backfaceFixed.js';

$(()=> {
  const $variant = $('.js-variant');
  // c-form-variant
  if($variant.length) {
    let $open = $('.js-variant-open');
    let $select = $variant.find('select');
    let $option = $select.find('option');
    let $choices = $('.js-variant-choices');
    let $list = $variant.find('.c-form-variant__choices__list');
    let $item = $('.js-variant-item');
    let $close = $('.js-variant-close');
    let show = false;
    const $body = $("body");
    let $product_infomation = $('.js-product-infomation');
    const scrollbarWidth = window.innerWidth - document.body.clientWidth;

    // イベント登録
    const setFunction = ()=> {
      const open = ()=> {
        if(!show) {
          if ($product_infomation.hasClass('is-fixed') && !$product_infomation.hasClass('is-fixed-end')) {
            $product_infomation[0].style.right = `${scrollbarWidth}px`;
          }
          backfaceFixed(true);
          $body.addClass('is-fixed');
          $choices.addClass('is-show');
          show = true;
          gsap.to($choices, 0, {
            // display: 'flex',
            opacity: 1,
            ease: 'circ',
          });
        }
      }
      const close = ()=> {
        if(show) {
          gsap.to($choices, 0, {
            // display: 'none',
            opacity: 0,
            // ease: 'circ',
            onComplete: () => {
              setTimeout(() => {
                backfaceFixed(false);
                $body.removeClass('is-fixed');
                $choices.removeClass('is-show');
                $product_infomation[0].style.right = `0px`;
                show = false;
              }, 300);
            }
          });
        }
      }
      $item.on('click', function() {
        const index = $item.index(this);
        $option.eq(index).prop('selected', true).change();
        close();
      });
      $open.on('click', function() {
        open();
      });
      $close.on('click', function() {
        close();
      });
      $(window).on('click', function(e) {
        const name = e.target.className;
        if(!name.match(/c-form-variant/)) {
          close();
        }
      });
    }
    setFunction();
  }
})